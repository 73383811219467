import {
  OnUserLoginFunction,
  PageViewFunction,
  AnalyticsEvent,
  AnalyticService,
  TrackEventFunction,
  SetProfilePropertiesFunction,
} from "@neurosolutionsgroup/analytics";
import { CustomWindow } from "./custom.window";
import Tools from "@neurosolutionsgroup/tools";

declare let window: CustomWindow;

const init = (): void => {};

const onUserLogin: OnUserLoginFunction = (
  distinctId,
  language,
  email,
  created
) => {
  if (window.cio) {
    window.cio.identify({
      id: distinctId,
      email,
      language,
      created_at: created,
      timezone: Tools.Time.Dates.getTimezone(),
    });
  } else {
    console.warn("Customer.IO called but SDK not initialised.");
  }
};

const pageView: PageViewFunction = (path) => {
  console.log("pageView not implemented for Customer.io SDK service.");
};

const trackEvent: TrackEventFunction = (eventName, properties) => {
  if (window.cio) {
    window.cio.track(eventName, properties);
  } else {
    console.warn("Customer.IO called but SDK not initialised.");
  }
};

const setProfileProperties: SetProfilePropertiesFunction = (properties) => {
  if (window.cio) {
    window.cio.identify(stripUserAttributes(properties));
  } else {
    console.warn("Customer.IO called but SDK not initialised.");
  }
};

const setOnceProfileProperties: SetProfilePropertiesFunction = (properties) => {
  console.warn("setOnceProperties is not implemented for Customer.IO");
};

const handleEvent = (event: AnalyticsEvent) => {
  if (window.cio) {
    trackEvent(event.name, event.eventProperties);

    if (event.setProperties) {
      setProfileProperties(event.setProperties);
    }
  } else {
    console.warn("Customer.IO called but SDK not initialised.");
  }
};

const stripUserAttributes = (
  attributes: Record<string, unknown>
): Record<string, unknown> => {
  const newAttributes = { ...attributes };

  const attributesToStrip = ["$email", "Email"];

  attributesToStrip.forEach((stripKey) => {
    if (Object.keys(newAttributes).includes(stripKey)) {
      delete newAttributes[stripKey];
    }
  });

  return newAttributes;
};

export const getCustomerIOSDKService = (): AnalyticService => {
  return {
    name: "CustomerIO",
    init: () => init(),
    functions: {
      onUserLogin,
      pageView,
      trackEvent,
      setProfileProperties,
      setOnceProfileProperties,
    },
    handleEvent,
  };
};
