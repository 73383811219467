import { Box, Grid, Input, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export interface DateTextInputProps {
  date: number | null;
  setDate: (value: number | null) => void;
  autoFocus?: boolean;
  dataCyRoot?: string;
}

type InputStep = {
  value: string;
  setFunction: (value: string) => void;
  step: Step;
  ref: React.RefObject<HTMLInputElement>;
};

enum Step {
  day = "day",
  month = "month",
  year = "year",
}

const DateTextInput: React.FC<DateTextInputProps> = ({
  date,
  setDate,
  autoFocus,
  dataCyRoot,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [step, setStep] = useState<Step | undefined>(
    autoFocus ? Step.day : undefined
  );
  const [error, setError] = useState<"invalid" | undefined>(undefined);

  useEffect(() => {
    if (date === null) {
      setDay("");
      setMonth("");
      setYear("");
    }
  }, [date]);

  const dayRef = useRef<HTMLInputElement>(null);
  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  const inputSteps: InputStep[] = [
    { value: day, setFunction: setDay, step: Step.day, ref: dayRef },
    { value: month, setFunction: setMonth, step: Step.month, ref: monthRef },
    { value: year, setFunction: setYear, step: Step.year, ref: yearRef },
  ];

  useEffect(() => {
    if (date === null) {
      setDay("");
      setMonth("");
      setYear("");
    }
  }, [date]);

  useEffect(() => {
    if (autoFocus) {
      dayRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (!(year && year.length === 4 && month && day)) {
      return;
    }
    if (!(isDayValid() && isMonthValid() && isYearValid())) {
      setError("invalid");
      setDate(null);
      return;
    }

    setDate(new Date(parseInt(year), parseInt(month), parseInt(day)).valueOf());
  }, [day, month, year]);

  useEffect(() => {
    if (!error) {
      return;
    }
    if (!isYearValid()) {
      setYear("");
      yearRef.current?.focus();
      setStep(Step.year);
    }
    if (!isMonthValid()) {
      setMonth("");
      monthRef.current?.focus();
      setStep(Step.month);
    }
    if (!isDayValid()) {
      setDay("");
      dayRef.current?.focus();
      setStep(Step.day);
    }
  }, [error]);

  const onChange = (value: string, setFunction: (value: string) => void) => {
    setError(undefined);
    const patern = new RegExp("^[0-9]*$");
    if (!patern.test(value)) {
      return;
    }
    setFunction(value);
  };

  const isDayValid = (): boolean => {
    return parseInt(day) <= 31;
  };

  const isMonthValid = (): boolean => {
    return parseInt(month) <= 12;
  };

  const isYearValid = (): boolean => {
    const value = parseInt(year);
    const currentYear = new Date().getFullYear();
    return value > currentYear - 100 && value < currentYear;
  };

  useEffect(() => {
    const automaticFocusChange = () => {
      if (step === Step.day && day.length >= 2) {
        setStep(Step.month);
        monthRef.current?.focus();
      } else if (step === Step.month && month.length >= 2) {
        setStep(Step.year);
        yearRef.current?.focus();
      }
    };
    automaticFocusChange();
  }, [day, month]);

  return (
    <Box>
      <Grid container spacing={1}>
        {inputSteps.map((i) => {
          return (
            <Grid
              item
              sx={{
                width: "25vw",
              }}
              key={i.step}
            >
              <Input
                value={i.value}
                data-cy={(dataCyRoot ?? "kid-birth-") + i.step}
                onChange={(e) => onChange(e.target.value, i.setFunction)}
                placeholder={t(
                  "settings.child.dateInput.placeholder." + i.step
                )}
                inputProps={{
                  pattern: "\\d*",
                  inputMode: "numeric",
                  maxLength: i.step === Step.year ? 4 : 2,
                }}
                disableUnderline={true}
                onClick={() => setStep(i.step)}
                inputRef={i.ref}
                sx={{
                  "backgroundColor": "#fff",
                  "borderRadius": 2,
                  "paddingX": "1em",
                  "paddingY": "6px",
                  "&::placeholder": {
                    opacity: "0.2",
                  },
                  "input": {
                    textAlign: "center",
                  },
                }}
              />
            </Grid>
          );
        })}
      </Grid>
      {error ? (
        <Grid sx={{ paddingX: "1em" }}>
          <Typography fontSize={"1em"} color={palette.error.main}>
            {t("settings.child.invalidBirthDate")}
          </Typography>
        </Grid>
      ) : null}
    </Box>
  );
};

export default DateTextInput;
