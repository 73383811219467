import { AnalyticsEvent } from ".";

export enum PremiumFeature {
  Downgrade,
  Child,
  LockedChild,
  RoutineMidday,
  RoutineAfternoon,
  RoutineEvening,
  RoutineCustom,
  TaskIcon,
  AddTask,
  Challenge,
  ChallengeTab,
  Chatbot,
  ExportTaskAnalysis,
  ExportMedical,
  GraphPeriodMedical,
  GraphPeriodTaskAnalysis,
  GraphPeriodProgress,
  GraphProgress,
}

const featureEventNameMap: Record<PremiumFeature, string> = {
  [PremiumFeature.Downgrade]: "Downgrade Reactivate Clicked",
  [PremiumFeature.Child]: "Premium Add Child Clicked",
  [PremiumFeature.LockedChild]: "Premium Locked Child Clicked",
  [PremiumFeature.RoutineMidday]: "Premium Routine Template Midday Clicked",
  [PremiumFeature.RoutineAfternoon]:
    "Premium Routine Template Afternoon Clicked",
  [PremiumFeature.RoutineEvening]: "Premium Routine Template Evening Clicked",
  [PremiumFeature.RoutineCustom]:
    "Premium Routine Template Personalized Clicked",
  [PremiumFeature.TaskIcon]: "Premium Task Icon Clicked",
  [PremiumFeature.AddTask]: "Premium Add Task Clicked",
  [PremiumFeature.Challenge]: "Premium Challenge Creation Clicked",
  [PremiumFeature.ChallengeTab]: "Premium Challenge Tab Clicked",
  [PremiumFeature.Chatbot]: "Premium Coach Chat Clicked",
  [PremiumFeature.ExportTaskAnalysis]: "Premium Export Task Analysis Clicked",
  [PremiumFeature.ExportMedical]: "Premium Export Medical Clicked",
  [PremiumFeature.GraphPeriodMedical]:
    "Premium Graph Medical Time Period Clicked",
  [PremiumFeature.GraphPeriodTaskAnalysis]:
    "Premium Graph Task Analysis Time Period Clicked",
  [PremiumFeature.GraphPeriodProgress]:
    "Premium Graph Progress Time Period Clicked",
  [PremiumFeature.GraphProgress]: "Premium Graph Progress Graph Clicked",
};

export const createPremiumFeatureEvent = (
  feature: PremiumFeature,
  eventProperties?: Record<string, unknown>
): AnalyticsEvent => {
  return {
    name: featureEventNameMap[feature],
    eventProperties,
  };
};
