import lodash from "lodash";
import {
  Coupon,
  LocalizationInfo,
  Price,
  PromoCodeResponse,
} from "@neurosolutionsgroup/models";
import { getHeaders, getURL, handleApiError } from "../utils";
import axios from "axios";

export interface StripePrice {
  id: string;
  currency: string;
  productId: string;
  recurring: {
    interval: string;
    intervalCount: number;
    trialPeriodDays: number;
  };
  type: string;
  unitAmount: number;
  unitAmountDecimal: number;
}

export interface StripeCheckoutSessionResponse {
  sessionId: string;
}

const camelizeKeys = (obj: unknown): StripePrice => {
  if (obj !== null && (obj as Record<string, unknown>).constructor === Object) {
    return Object.keys(obj as Record<string, unknown>).reduce(
      (result, key) => ({
        ...result,
        [lodash.camelCase(key)]: camelizeKeys(
          (obj as Record<string, unknown>)[key]
        ),
      }),
      {}
    ) as StripePrice;
  }
  return obj as StripePrice;
};

const stripePriceToAppPrice = (stripePrice: StripePrice): Price | null => {
  if (!["year", "month"].includes(stripePrice.recurring.interval)) {
    return null;
  } else if (!["usd", "cad", "eur"].includes(stripePrice.currency)) {
    return null;
  }

  return {
    id: stripePrice.id,
    interval: stripePrice.recurring.interval as "year" | "month",
    currency: stripePrice.currency as "usd" | "cad" | "eur",
    unitAmount: stripePrice.unitAmount,
    unitAmountDecimal: stripePrice.unitAmountDecimal,
    productId: stripePrice.productId,
  };
};

export interface GetPricesResponse {
  prices: Price[];
  coupon?: Coupon;
}

const getPrices = async (): Promise<GetPricesResponse> => {
  const ENDPOINT =
    (process.env.REACT_APP_FIREBASE_FUNCTIONS_URL ??
      process.env.REACT_APP_FIREBASE_FUNCTIONS_URL) + "/billing/stripe/prices";

  return fetch(ENDPOINT)
    .then((response) => {
      return response.json().then((response) => {
        const prices = (response.prices as StripePrice[]).flatMap((sp) => {
          const newSP = camelizeKeys(sp);
          const price = stripePriceToAppPrice(newSP);

          return price ? price : [];
        });

        let coupon = undefined;

        coupon = response.referralDiscount;

        return {
          prices,
          coupon,
        };
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const createCheckoutSession = async (
  priceId: string,
  localization: LocalizationInfo,
  promoCodeId?: string
): Promise<StripeCheckoutSessionResponse> => {
  const URL = getURL() + "/billing/stripe/checkout-session";

  const headers = await getHeaders();

  const body = { priceId, localization, promoCodeId };

  try {
    const result = await axios.post<StripeCheckoutSessionResponse>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const createPortalSession = async (): Promise<Record<string, unknown>> => {
  const URL = getURL() + "/billing/stripe/portal-session";

  const headers = await getHeaders();

  try {
    const result = await axios.post(
      URL,
      {},
      {
        headers,
      }
    );

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const validatePromoCode = async (
  promoCode: string
): Promise<PromoCodeResponse> => {
  const URL = getURL() + "/billing/stripe/promo-code";

  const headers = await getHeaders();

  const body = {
    promoCode,
  };

  try {
    const result = await axios.post<PromoCodeResponse>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const activateFreePromoCode = async (
  promoCode: string,
  priceId: string
): Promise<PromoCodeResponse> => {
  const URL = getURL() + "/billing/stripe/free-subscription";

  const headers = await getHeaders();

  const body = {
    promoCode,
    priceId,
  };

  try {
    const result = await axios.post<PromoCodeResponse>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Stripe = {
  activateFreePromoCode,
  getPrices,
  createCheckoutSession,
  createPortalSession,
  validatePromoCode,
};

export default Stripe;
