import Products from "pages/app/billing/components/Products";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const ExpiredSubscription = (): JSX.Element => {
  const { t } = useTranslation("webappBilling");

  return (
    <div className="expired-subscription">
      <Container>
        <h2 className="text-colour-white">{t("products.title")}</h2>

        <Products />

        <Row>
          <Col md={12} className="expired-subscription__message-wrap">
            <div className="expired-subscription__message">
              <p>{t("products.trial-message")}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ExpiredSubscription;
