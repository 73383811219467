import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { BevelButton, PasswordInput, TextInput } from "components/inputs";

interface Props {
  isSubmitEnabled: boolean;
  onSubmit: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => void;
  referral: boolean;
}

const RegistrationFormComponent = ({
  isSubmitEnabled,
  onSubmit,
  referral,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "registration";

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        passwordconfirm: "",
        firstName: "",
        lastName: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string()
          .email(t("fields.email.errors.invalid"))
          .required(t("fields.generic.errors.required")),
        password: Yup.string()
          .min(6, t("fields.password.errors.length"))
          .max(100, t("fields.password.errors.length"))
          .required(t("fields.generic.errors.required")),
        passwordconfirm: Yup.string()
          .required(t("fields.generic.errors.required"))
          .test(
            "passwords-match",
            t("fields.passwordconfirm.errors.match"),
            function (value) {
              return this.parent.password === value;
            }
          ),
        firstName: referral
          ? Yup.string()
              .min(3, t("fields.firstName.errors.length"))
              .max(40, t("fields.firstName.errors.length"))
              .required(t("fields.generic.errors.required"))
          : Yup.string(),
        lastName: referral
          ? Yup.string()
              .min(3, t("fields.lastName.errors.length"))
              .max(40, t("fields.lastName.errors.length"))
              .required(t("fields.generic.errors.required"))
          : Yup.string(),
      })}
      onSubmit={async (values) => {
        onSubmit(
          values.email,
          values.password,
          referral ? values.firstName : undefined,
          referral ? values.lastName : undefined
        );
      }}
    >
      <Form className={blockName + "__form"}>
        <TextInput label={t("fields.email.label")} name="email" type="email" />
        <PasswordInput label={t("fields.password.label")} name="password" />
        <PasswordInput
          label={t("fields.passwordconfirm.label")}
          name="passwordconfirm"
        />
        {referral ? (
          <>
            <TextInput
              label={t("fields.firstName.label")}
              name="firstName"
              type="text"
            />
            <TextInput
              label={t("fields.lastName.label")}
              name="lastName"
              type="text"
            />
          </>
        ) : null}

        <div className={blockName + "__button-group"}>
          <BevelButton
            className="btn btn-primary w-100"
            type="submit"
            disabled={!isSubmitEnabled}
            onClick={() => {
              return true;
            }}
          >
            {t("pages.registration.submit")}
          </BevelButton>
        </div>

        <div className={blockName + "__terms"}>
          <p>{t("terms.text")}</p>
          <p>
            <a
              href={t("terms.use.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("terms.use.text")}
            </a>
            &nbsp;|&nbsp;
            <a
              href={t("terms.privacy.link")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("terms.privacy.text")}
            </a>
          </p>
        </div>
      </Form>
    </Formik>
  );
};

export default RegistrationFormComponent;
