import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Palette } from "@mui/material/styles/createPalette";
import { responsiveFontSizes, Theme } from "@mui/material";

import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/400-italic.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";

export const typography = (palette: Palette): TypographyOptions => {
  const headerStyle = {
    fontWeight: "bold",
  };

  return {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    allVariants: {
      color: palette.secondary.main,
    },
    h1: {
      ...headerStyle,
      fontSize: "2rem",
    },
    h2: {
      ...headerStyle,
      fontSize: "1.5rem",
    },
    h3: {
      ...headerStyle,
      fontSize: "1.17rem",
    },
    h4: {
      ...headerStyle,
      fontSize: "1rem",
    },
    h5: {
      ...headerStyle,
      fontSize: "0.83rem",
    },
    h6: {
      ...headerStyle,
      fontSize: "0.67rem",
    },
    label: {
      fontWeight: "bold",
      color: palette.secondary.main,
      fontSize: "1rem",
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      marginBottom: "0.25rem",
    },
  };
};

export const customResponsiveFontSizes = (theme: Theme): Theme => {
  theme = responsiveFontSizes(theme);

  theme.typography.label = {
    ...theme.typography.label,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.9rem",
    },
  };

  return theme;
};
