import { Loader } from "@neurosolutionsgroup/components";
import RecaptchaMessage from "./../components/recaptcha/RecaptchaMessage";
import React from "react";
import LoginFormComponent from "./LoginFormComponent";
import { BevelButton } from "components/inputs";
import IconAssets from "assets/GeneralIcons";
import { useTranslation } from "react-i18next";

interface Props {
  isLoading: boolean;
  autoCheckout?: boolean;
  additionnalTitle?: string;
  onSubmit: (email: string, password: string) => void;
  onLoginGoogle?: VoidFunction;
  onLoginApple?: VoidFunction;
  passwordLink?: string;
}

const LoginPageComponent = ({
  isLoading = false,
  autoCheckout = false,
  additionnalTitle,
  onSubmit,
  onLoginGoogle,
  onLoginApple,
  passwordLink,
}: Props): JSX.Element => {
  const { t } = useTranslation(["webappAuthentication"]);

  const blockName = "login";

  return (
    <div className={blockName}>
      {isLoading ? <Loader /> : null}
      <div className={blockName + "__body"}>
        <h2 className={blockName + "__title"}>{t("pages.login.title")}</h2>
        {additionnalTitle && <p>{additionnalTitle}</p>}
        <LoginFormComponent
          autoCheckout={autoCheckout}
          isLoading={isLoading}
          onSubmit={onSubmit}
          passwordLink={passwordLink}
        />

        {onLoginGoogle ? (
          <div className={blockName + "__button-group google-sign-in my-2"}>
            <BevelButton
              className="btn btn-primary-alt w-100"
              disabled={isLoading}
              onClick={onLoginGoogle}
            >
              <div className="d-flex justify-content-center align-items-center">
                <img src={IconAssets.GoogleSmall} alt="Google" />
                {t("pages.login.google")}
              </div>
            </BevelButton>
          </div>
        ) : null}

        {onLoginApple ? (
          <div className={blockName + "__button-group apple-sign-in my-2"}>
            <BevelButton
              className="btn btn-primary-alt w-100"
              disabled={isLoading}
              onClick={onLoginApple}
            >
              <div className="d-flex justify-content-center align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="7 0 15.460902 18.441177"
                >
                  <g
                    stroke="none"
                    fill="none"
                    id="g184"
                    transform="translate(-0.7500005,-10.779412)"
                  >
                    <path
                      fill="#000"
                      d="m 15.709949,14.884615 c 0.857597,0 1.932613,-0.579783 2.572791,-1.352829 0.579784,-0.700572 1.002543,-1.678957 1.002543,-2.657342 0,-0.132867 -0.01208,-0.265734 -0.03624,-0.374444 -0.954227,0.03624 -2.101716,0.640178 -2.790209,1.44946 -0.543548,0.61602 -1.03878,1.582326 -1.03878,2.572791 0,0.144945 0.02416,0.289891 0.03624,0.338207 0.06039,0.01208 0.157025,0.02416 0.253655,0.02416 z M 12.690242,29.5 c 1.171646,0 1.691036,-0.785124 3.152574,-0.785124 1.485696,0 1.811825,0.760966 3.116338,0.760966 1.280357,0 2.137953,-1.183725 2.947235,-2.343293 0.905912,-1.328671 1.280356,-2.633185 1.304514,-2.693579 -0.08455,-0.02416 -2.536555,-1.026701 -2.536555,-3.841068 0,-2.439924 1.932613,-3.539097 2.041323,-3.623649 -1.280356,-1.835982 -3.225048,-1.884297 -3.756517,-1.884297 -1.43738,0 -2.609027,0.869675 -3.345835,0.869675 -0.797203,0 -1.848061,-0.82136 -3.092181,-0.82136 -2.367451,0 -4.771138,1.95677 -4.771138,5.652892 0,2.294978 0.8938334,4.722823 1.993007,6.293071 C 10.685156,28.412905 11.506516,29.5 12.690242,29.5 Z"
                      id="path182"
                    />
                  </g>
                </svg>
                {t("pages.login.apple")}
              </div>
            </BevelButton>
          </div>
        ) : null}

        <RecaptchaMessage />
      </div>
    </div>
  );
};

export default LoginPageComponent;
