import Tools from "@neurosolutionsgroup/tools";
import { AnalyticService, PageViewFunction } from "../../models";

const init = (): void => {
  console.info("[Analytics] Initialised.");
};

const onUserLogin = (distinctId: string, language: string): void => {
  console.info(`[Analytics] User logged in ${distinctId}.`, {
    language,
    timezone: Tools.Time.Dates.getTimezone(),
  });
};

const pageView: PageViewFunction = (path) => {
  console.info(`[Analytics] Event: Page View "${path}"`);
};

const trackEvent = (
  eventName: string,
  properties?: { [key: string]: unknown }
): void => {
  console.info(`[Analytics] Event: "${eventName}" tracked.`, properties);
};

const setProfileProperties = (properties: { [key: string]: unknown }): void => {
  console.info(`[Analytics] Profile updated.`, properties);
};

const setOnceProfileProperties = (properties: {
  [key: string]: unknown;
}): void => {
  console.info(`[Analytics] Profile updated (set once).`, properties);
};

const functions = {
  onUserLogin,
  pageView,
  trackEvent,
  setProfileProperties,
  setOnceProfileProperties,
};

export const ConsoleService: AnalyticService = {
  name: "Console",
  init,
  functions,
};
