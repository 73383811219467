import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Row, Col } from "react-bootstrap";
import { PlainCard } from "components/layout/cards";
import { SuspenseLoader } from "components/graphics";
import { ProtectedFunction } from "components/navigation";

import Validation from "./validation/Validation";
import NoChildPlaceholder from "./NoChildPlaceholder";
import ChildPicker from "components/inputs/ChildPicker/ChildPicker";
import Today from "./graphics/Today/Today";
import AppDataContext from "hooks/data/AppDataContext";
import useChildren from "hooks/data/useChildren";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";
import { ExpiredSubscription } from "components/alerts";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import SubscriptionSuccessAlert from "components/alerts/SubscriptionSuccessAlert";

const Component = () => {
  //  Hooks  //
  //  Localization.
  const { t } = useTranslation(["webappDashboard", "webappBase"]);

  const location = useLocation();
  const urlParams = location.search;

  const [showPurchaseComplete, setShowPurchaseComplete] = useState(false);

  useEffect(() => {
    const params = queryString.parse(urlParams);

    if (params.purchaseComplete !== undefined) {
      setShowPurchaseComplete(true);
    }
  }, []);

  const {
    selectors: { userProfile },
  } = useUserProfile();

  const { dataFetched } = useContext(AppDataContext);

  const {
    selectors: { childIds, childrenById, selectedChildId, tasksToValidate },
    actions: { setSelectedChildId },
  } = useChildren();

  const changeUserIdHandler = (id: string) => {
    setSelectedChildId(id);
  };

  return (
    <>
      <div className="py-3">
        {!dataFetched ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <SubscriptionSuccessAlert
              open={showPurchaseComplete}
              onClose={() => setShowPurchaseComplete(false)}
            />
            {/* Test for no children. */}
            {!selectedChildId || (childIds && childIds.length === 0) ? (
              <Row>
                <Col>
                  <NoChildPlaceholder />
                </Col>
              </Row>
            ) : (
              <>
                {!userProfile?.isSubscribed && !userProfile?.isFreeTrial && (
                  <ExpiredSubscription />
                )}
                <Row>
                  <Col lg={5}>
                    <div>
                      <Row className="grid-dashboard-top mb-3">
                        {childIds.length > 0 ? (
                          <Col>
                            <div className="">
                              <ChildPicker
                                userDefinitions={childrenById}
                                tasksToValidate={tasksToValidate}
                                userId={selectedChildId}
                                setUserId={changeUserIdHandler}
                              />
                            </div>
                          </Col>
                        ) : null}
                      </Row>
                      <Row>
                        <Col>
                          <ProtectedFunction
                            isSubscribed={userProfile?.isSubscribed ?? false}
                            isFreeTrial={userProfile?.isFreeTrial ?? false}
                            function={t("functions.validation")}
                          >
                            <PlainCard className="p-3 dashboard-section-validation">
                              <Validation />
                            </PlainCard>
                          </ProtectedFunction>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col lg={7}>
                    <div>
                      <Row>
                        <Col>
                          <Today />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default function Dashboard(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
