import React, { useState } from "react";
import { useTranslation } from "react-i18next";

//  Components.
import { SuspenseLoader } from "components/graphics";
import BevelButton from "components/inputs/BevelButton";

//  Form.
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { PasswordField } from "components/forms";

//  Notifications.
import { store } from "react-notifications-component";
import {
  successNotification,
  dangerNotification,
} from "components/notifications";
import useUserProfile from "hooks/account/useUserProfile";
import { Loader } from "@neurosolutionsgroup/components";

const Component = () => {
  const { t } = useTranslation(["webappSettings", "webappBase"]);

  const [isLoading, setIsLoading] = useState(false);

  const {
    actions: { resetPin },
  } = useUserProfile();

  return (
    <Formik
      initialValues={{
        pin: "",
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        pin: Yup.string()
          .required(t("webappBase:forms.errors.required"))
          .matches(
            new RegExp("^[1-9]{4}$"),
            t("auth.inapp.pin.errors.invalid")
          ),
      })}
      onSubmit={async (values) => {
        setIsLoading(true);
        try {
          await resetPin(values.pin);

          store.addNotification(
            successNotification("", t("auth.inapp.pin.success"))
          );
        } catch (err) {
          store.addNotification(
            dangerNotification(
              t("webappBase:forms.errors.title"),
              t("webappBase:forms.errors.general") +
                ": " +
                t(
                  "webappBase:forms.errors." +
                    (err instanceof Error ? err.message : "unknown")
                ),
              false
            )
          );
        } finally {
          setIsLoading(false);
        }
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Form>
          <div className="form-group">
            <PasswordField name="pin" hideLabel={true} />
          </div>
          <div className="form-group">
            <BevelButton
              type="submit"
              className="btn btn-primary float-right"
              disabled={isLoading}
            >
              {t("auth.inapp.pin.submit")}
            </BevelButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default function PinReset(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
