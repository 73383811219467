import Tools from "@neurosolutionsgroup/tools";
import useTasks from "hooks/data/useTasks";
import useLanguage from "hooks/useLanguage";
import { FrontEndRoutine } from "models/Types";
import React, { FunctionComponent, HTMLAttributes } from "react";

interface RoutineCardProps extends HTMLAttributes<HTMLDivElement> {
  routine: FrontEndRoutine;
  index: number;
}

const RoutineCard: FunctionComponent<RoutineCardProps> = ({
  routine,
  index,
  ...props
}) => {
  //  HOOKS //
  //  State.
  const {
    selectors: { tasksById },
  } = useTasks();

  const { language } = useLanguage();

  return (
    <div className={"routine-card color-" + index + " " + props.className}>
      <div className="top"></div>
      <div
        className={
          "routine-card-inner routine-card-inner-" +
          (index % 2 === 0 ? "up" : "down")
        }
      >
        <div className={"routine-card-title color-" + index}>
          {routine.name}
        </div>
        <div className="routine-card-time">
          {Tools.Time.Strings.localizedTimeFromSeconds(routine.start, language)}{" "}
          - {Tools.Time.Strings.localizedTimeFromSeconds(routine.end, language)}
        </div>
        <div className="routine-card-body">
          <ul>
            {routine.tasks
              .filter((task) => !tasksById[task].deleted)
              .map((task) => {
                return <li key={task}>{tasksById[task].name}</li>;
              })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RoutineCard;
