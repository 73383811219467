import {
  Challenge,
  ChallengeChildValidationBody,
  ChallengeParentValidationBody,
  FirestoreChallenge,
} from "@neurosolutionsgroup/models";
import { getHeaders, getURL, handleApiError } from "./utils";
import axios from "axios";

const approveChallenge = async (
  challengeId: string,
  approvalStatus: boolean,
  message?: string
): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge/" + challengeId + "/parentApproval";

  const headers = await getHeaders(true, "approveChallenge");

  try {
    const result = await axios.put(
      URL,
      { approvalStatus, message },
      { headers }
    );

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const childValidation = async (
  challengeId: string,
  body: ChallengeChildValidationBody
): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge/" + challengeId + "/childValidation";

  const headers = await getHeaders(true, "childValidation");

  try {
    const result = await axios.put(URL, body, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const childWasNotified = async (challengeId: string): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge/" + challengeId + "/childNotification";

  const headers = await getHeaders(true, "childNotification");

  try {
    const result = await axios.put(URL, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const deleteChallenge = async (challengeId: string): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge/" + challengeId;

  const headers = await getHeaders(true, "deleteChallenge");

  try {
    const result = await axios.delete<FirestoreChallenge>(URL, { headers });

    return firestoreChallengeToChallenge(result.data);
  } catch (err) {
    return handleApiError(err);
  }
};

const getChallenges = async (): Promise<Challenge[]> => {
  const URL = getURL() + "/api/challenge";

  const headers = await getHeaders(true, "postChallenge");

  try {
    const result = await axios.get<FirestoreChallenge[]>(URL, { headers });

    return result.data.map((fsChallenge) =>
      firestoreChallengeToChallenge(fsChallenge)
    );
  } catch (err) {
    return handleApiError(err);
  }
};

const parentValidation = async (
  challengeId: string,
  body: ChallengeParentValidationBody
): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge/" + challengeId + "/parentValidation";

  const headers = await getHeaders(true, "parentValidation");

  try {
    const result = await axios.put(URL, body, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const postChallenge = async (challenge: Challenge): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge";

  const headers = await getHeaders(true, "postChallenge");

  try {
    const result = await axios.post<FirestoreChallenge>(URL, challenge, {
      headers,
    });

    return firestoreChallengeToChallenge(result.data);
  } catch (err) {
    return handleApiError(err);
  }
};

const putChallenge = async (challenge: Challenge): Promise<Challenge> => {
  const URL = getURL() + "/api/challenge";

  const headers = await getHeaders(true, "putChallenge");

  try {
    const result = await axios.put<FirestoreChallenge>(URL, challenge, {
      headers,
    });

    return firestoreChallengeToChallenge(result.data);
  } catch (err) {
    return handleApiError(err);
  }
};

const terminateExpiredChallenges = async (): Promise<void> => {
  const URL = getURL() + "/api/challenge/terminateExpired";

  const headers = await getHeaders(true, "terminateExpiredChallenges");

  try {
    return axios.post(URL, undefined, {
      headers,
    });
  } catch (err) {
    return handleApiError(err);
  }
};

const ChallengeService = {
  approveChallenge,
  childValidation,
  childWasNotified,
  deleteChallenge,
  getChallenges,
  parentValidation,
  postChallenge,
  putChallenge,
  terminateExpiredChallenges,
};

export default ChallengeService;

const firestoreChallengeToChallenge = (
  fsChallenge: FirestoreChallenge
): Challenge => ({
  ...fsChallenge,
  childWasNotified: fsChallenge.childWasNotified ?? undefined,
  childNotificationsCount: fsChallenge.childNotificationsCount ?? undefined,
  requiresApproval: fsChallenge.requiresApproval ?? undefined,
  createdById: fsChallenge.createdById ?? undefined,
  createdByName: fsChallenge.createdByName ?? undefined,
  approvalDate: fsChallenge.approvalDate ?? undefined,
  approvalStatus: fsChallenge.approvalStatus ?? undefined,
  lastChildExecution: fsChallenge.lastChildExecution ?? undefined,
  cancelled: fsChallenge.cancelled ?? undefined,
  rejectionReason: fsChallenge.rejectionReason ?? undefined,
});
