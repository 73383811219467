import React, { useEffect, useMemo, useRef } from "react";
import { SuspenseLoader } from "components/graphics";
import { useTranslation } from "react-i18next";

import Task from "./Task";
import { BevelButton, BevelBorderButton } from "components/inputs";
import useChildren from "hooks/data/useChildren";
import useTasks from "hooks/data/useTasks";
import useRoutines from "hooks/data/useRoutines";
import { useState } from "react";

import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications";
import { Loader } from "@neurosolutionsgroup/components";
import { TaskHistory } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import { ValidationTaskHistory } from "hooks/data/ChildContext";
import AvatarAssets from "assets/avatars";
import useLanguage from "hooks/useLanguage";

const Component = () => {
  const date = useRef<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  //  Hooks.
  //  Language management.
  const { t } = useTranslation(["webappDashboard", "webappBase"]);
  const { language } = useLanguage();

  const {
    selectors: { selectedChildId, tasksToValidate },
  } = useChildren();
  const {
    selectors: { tasksById },
    actions: { validateTasks, setTaskStatus, getMissingHistories },
  } = useTasks();
  const {
    selectors: { routinesById },
  } = useRoutines();

  // Get missed histories on userId change.
  useEffect(() => {
    if (selectedChildId) {
      setIsLoading(true);

      getMissingHistories(selectedChildId).finally(() => {
        setIsLoading(false);
      });
    }
  }, [selectedChildId]);

  //  Reset date on component load.
  useEffect(() => {
    date.current = null;
  }, []);

  //  Functions.
  //  Has date changed?
  const checkDate = (dueTime: number) => {
    if (date) {
      if (date.current === dueTime) {
        return false;
      }
    }
    return true;
  };

  //  Generate date string from epoch time.
  const getDate = (dueTime: number) => {
    date.current = dueTime;
    const displayDate = new Date(dueTime * 1000);
    return (
      displayDate.getFullYear().toString() +
      "/" +
      displayDate.getMonth().toString() +
      "/" +
      displayDate.getDate().toString()
    );
  };

  const setTaskStatusHandler = (taskIndex: number, status: boolean | null) => {
    if (selectedChildId) {
      const payload = {
        userId: selectedChildId,
        taskIndex: taskIndex,
        status: status,
      };

      setTaskStatus(payload);
    }
  };

  const switchAllTaskHandler = (status: boolean | null) => {
    if (selectedChildId) {
      tasksToValidate[selectedChildId].forEach((task, index) => {
        const payload = {
          userId: selectedChildId,
          taskIndex: index,
          status: status,
        };
        setTaskStatus(payload);
      });
    }
  };

  const validateTasksHandler = () => {
    if (selectedChildId) {
      setIsLoading(true);

      //  Get tasks that have been confirmed.
      const tasks: TaskHistory[] = [];

      tasksToValidate[selectedChildId]
        .filter((t) => !!t.validated)
        .forEach((task) => {
          tasks.push({ ...task, confirmTime: Tools.Time.Dates.getTimeStamp() });
        });

      validateTasks(tasks, selectedChildId)
        .catch((err) => {
          store.addNotification(
            dangerNotification(
              t("webappBase:forms.errors.title"),
              t("webappBase:forms.errors.general") +
                ": " +
                t("webappBase:forms.errors." + err.message),
              true
            )
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const validationTasks: ValidationTaskHistory[] = useMemo(() => {
    if (selectedChildId) {
      return tasksToValidate[selectedChildId].map((h) => ({
        ...h,
        childStatus: h.status,
        validated: h.validated ?? false,
      }));
    } else {
      return [];
    }
  }, [tasksToValidate, selectedChildId]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div id="validation-top"></div>
          {validationTasks.length > 0 ? null : (
            <div className="d-flex justify-content-center align-items-center flex-column">
              <h3 className="w-100 ml-3 text-left">
                {t("validation.count", {
                  count: validationTasks.length,
                })}
              </h3>
              <img
                className="w-100"
                alt="Girl avatar checking watch"
                src={AvatarAssets.Avatar_checkWatch_nobg}
              />
              <p className="pl-3 text-center">
                <b>{t("validation.notasks")}</b>
              </p>
            </div>
          )}
          <div className="p-3">
            <div>
              {validationTasks.length > 0 ? (
                <>
                  <div className="d-flex">
                    <h3 className="text-left">
                      {t("validation.count", {
                        count: validationTasks.length,
                      })}
                    </h3>
                    <div className="d-flex ml-auto mr-3 task-toggle-switch align-items-center">
                      <BevelBorderButton
                        className="btn btn-primary btn-task-validation btn-task-validation-false selected"
                        onClick={() => switchAllTaskHandler(false)}
                      >
                        <i className="icon-cross"></i>
                      </BevelBorderButton>
                      <BevelBorderButton
                        className="btn btn-primary btn-task-validation btn-task-validation-na ml-2 selected"
                        onClick={() => switchAllTaskHandler(null)}
                      >
                        N/A
                      </BevelBorderButton>
                      <BevelBorderButton
                        className="btn btn-primary btn-task-validation btn-task-validation-true ml-2 selected"
                        onClick={() => switchAllTaskHandler(true)}
                      >
                        <i className="icon-tick"></i>
                      </BevelBorderButton>
                    </div>
                  </div>
                </>
              ) : null}
              {validationTasks.map((task, taskIndex) => (
                <div key={task.task + "-" + task.dueTime}>
                  {checkDate(task.dueTime) ? (
                    <div className="task-date my-2">
                      <p>
                        {getDate(task.dueTime) +
                          " " +
                          Tools.Time.Strings.localizedTimeFromSeconds(
                            routinesById[tasksById[task.task].routine].start,
                            language
                          )}{" "}
                        <b>{routinesById[tasksById[task.task].routine].name}</b>
                      </p>
                    </div>
                  ) : null}
                  <div>
                    <Task
                      task={task}
                      index={taskIndex}
                      setTaskStatus={setTaskStatusHandler}
                    />
                  </div>
                </div>
              ))}
            </div>
            {validationTasks.length > 0 ? (
              <>
                <BevelButton
                  className="btn btn-primary float-right my-2"
                  onClick={() => validateTasksHandler()}
                >
                  {t("validation.validate")}
                </BevelButton>
              </>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default function Validation(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
