import React, { useEffect, useState } from "react";
import { SuspenseLoader } from "components/graphics";
import useStripe from "hooks/billing/useStripe";
import { useStripe as useStripeHook } from "@stripe/react-stripe-js";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Loader } from "@neurosolutionsgroup/components";
import Products from "./components/Products";
import { store } from "react-notifications-component";
import { dangerNotification } from "components/notifications";
import useWindowDimensions from "hooks/useWindowDimensions";
import MobileProducts from "./components/MobileProducts";
import FreeAccount from "./components/FreeAccount";
import useUserProfile from "hooks/account/useUserProfile";
import AlreadySubscribedAlert from "components/alerts/AlreadySubscribed";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { Coupon } from "@neurosolutionsgroup/models";

const Component = (): JSX.Element => {
  const ROOT_CLASS = "billing-page";

  const { t } = useTranslation([
    "webappSettings",
    "webappBilling",
    "webappBase",
  ]);
  const [loading, setLoading] = useState(false);
  const [promoLoading, setPromoLoading] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [promoError, setPromoError] = useState(false);
  const [validatedPromoCode, setValidatedPromoCode] = useState<Coupon>();
  const [isFree, setIsFree] = useState(false);

  const history = useHistory();
  const { breakpoints } = useWindowDimensions();

  const urlParams = useLocation().search;
  const params = queryString.parse(urlParams);

  const {
    actions: { setIsSubscribed },
    selectors: { userProfile },
  } = useUserProfile();

  const {
    selectors: { prices, localization },
    actions: {
      activateFreeSubscription,
      getPrices,
      getLocalization,
      validatePromoCode,
    },
  } = useStripe();

  const stripe = useStripeHook();

  useEffect(() => {
    setLoading(true);
    Promise.all([getPrices(), getLocalization()]).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (
      prices.length > 0 &&
      localization &&
      params.autoCheckout &&
      params.product &&
      !attempted
    ) {
      setAttempted(true);
      setLoading(true);

      const product = prices.find(
        (p) =>
          p.interval === params.product && p.currency === localization.currency
      );

      if (product) {
        FirebaseAPI.Billing.Stripe.createCheckoutSession(
          product.id,
          localization,
          params.promoCode?.toString()
        )
          .then(async (response) => {
            await stripe?.redirectToCheckout({
              sessionId: response.sessionId,
            });
          })
          .catch(() => {
            store.addNotification(
              dangerNotification(
                t("webappBase:forms.errors.title"),
                t("webappBase:forms.errors.general"),
                false
              )
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [prices, localization]);

  useEffect(() => {
    setPromoError(false);
  }, [promoCode]);

  const onPromoSubmit = async () => {
    setPromoLoading(true);
    try {
      const promo = await validatePromoCode(promoCode);
      if (
        promo.couponData?.percent_off &&
        promo.couponData.percent_off >= 100
      ) {
        // If valid and free, show unique result.
        setIsFree(true);
      } else {
        // If valid and not free, apply promo code to prices.
        setValidatedPromoCode(promo.couponData);
      }
    } catch {
      setPromoError(true);
    } finally {
      setPromoLoading(false);
    }
  };

  const onFreePromoSubmit = async () => {
    if (prices && localization) {
      setLoading(true);
      const price = prices.find(
        (p) => p.interval === "year" && p.currency === localization.currency
      );

      if (price) {
        try {
          const promo = await activateFreeSubscription(promoCode, price.id);
          if (promo.type === "free") {
            setIsSubscribed();
            history.push("/app/dashboard");
          }
        } catch {
          setPromoError(true);
        } finally {
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      {loading || !(prices.length > 0 && localization && userProfile) ? (
        <Loader />
      ) : (
        <div className={ROOT_CLASS + " mx-2"}>
          {userProfile.isSubscribed ? (
            <AlreadySubscribedAlert />
          ) : (
            <>
              <Row className="mt-3">
                <Col md={12}>
                  <h2>{t("webappBilling:products.title")}</h2>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col xs={12} sm={10} md={8}>
                  <h5 className="text-left">
                    {t("webappBilling:promo.title")}
                  </h5>
                </Col>
                <Col xs={8} sm={7} md={6}>
                  <div className={ROOT_CLASS + "__promo-wrapper"}>
                    <Form.Control
                      type="text"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.currentTarget.value)}
                      placeholder={t("webappBilling:promo.placeholder")}
                      className={
                        promoError ? ROOT_CLASS + "__promo-input--error" : ""
                      }
                    />
                    {validatedPromoCode || isFree ? (
                      <button
                        onClick={() => {
                          setValidatedPromoCode(undefined);
                          setPromoCode("");
                          setIsFree(false);
                        }}
                        className={ROOT_CLASS + "__promo-clear"}
                      >
                        <i className="icon-cross"></i>
                      </button>
                    ) : null}
                  </div>
                </Col>
                <Col xs={4} sm={3} md={2}>
                  <Button
                    onClick={onPromoSubmit}
                    className="btn btn-secondary w-100 promo__submit"
                  >
                    {promoLoading ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      t("webappBilling:promo.apply")
                    )}
                  </Button>
                </Col>
                {promoError ? (
                  <Col sm={8}>
                    <p className={ROOT_CLASS + "__promo-error"}>
                      {t("webappBilling:promo.error")}
                    </p>
                  </Col>
                ) : null}
              </Row>
              {isFree ? (
                <Row className="mt-3 justify-content-center">
                  <Col xs={12} md={10}>
                    <FreeAccount onSubmit={() => onFreePromoSubmit()} />
                  </Col>
                </Row>
              ) : (
                <>
                  {breakpoints.md ? (
                    <Products promoCode={validatedPromoCode} />
                  ) : (
                    <MobileProducts promoCode={validatedPromoCode} />
                  )}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default function Billing(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
