import { SuspenseLoader } from "components/graphics";
import { PlainCard } from "components/layout/cards";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface ProtectedFunctionProps extends React.HTMLAttributes<HTMLElement> {
  isSubscribed: boolean;
  isFreeTrial: boolean;
  function: string;
}

const Component: FunctionComponent<ProtectedFunctionProps> = (props) => {
  const { t } = useTranslation(["webappBase"]);

  return (
    <>
      {props.isSubscribed || props.isFreeTrial ? (
        <>{props.children}</>
      ) : (
        <>
          <PlainCard className="p-3">
            <h1>{props.function}</h1>
            <h2>{t("protectedfunction.message")}</h2>
          </PlainCard>
        </>
      )}
    </>
  );
};

export default function ProtectedFunction(
  props: ProtectedFunctionProps
): JSX.Element {
  return (
    <SuspenseLoader>
      <Component {...props} />
    </SuspenseLoader>
  );
}
