import { format, previousDay, Day } from "date-fns";

/**
 * Map of days of week to binary representation.
 * Index 0 = Monday, 6 = Sunday.
 */
const dayIndexes: number[] = [1, 2, 4, 8, 16, 32, 64];

/**
 * Function to check if a day is active within the days.
 * It performs a bit-by-bit operation, checking that the bit in binary representation of the day
 * is set to 1 in the binary representation of the days
 * @param {number} day The value of the day to check.
 * @param {number} days The days to check the day against.
 * @returns {boolean} Whether the day is active within the binary number days representation.
 */
const dayInDays = (day: number, days: number): boolean => {
  return !!(day & days);
};

/**
 * Toggle a day in a days number.
 * Performs a bit-by-bit operation on the binary representation of the days number to switch
 * the bit that represents the day.
 * @param {number} day The day to toggle.
 * @param {number} days The days to toggle the day in.
 * @returns {number} The new days number.
 */
const toggleDay = (day: number, days: number): number => {
  return days ^ day;
};

/**
 * Deactivate specific days within a days number.
 * Performs bit-by-bit operation to deactivate the bit that represents the day passed in the
 * days number.
 * @param {number} days The days number within which to deactivate days.
 * @param {number} daysToDeactivate The days to deactivate.
 * @returns {number} The new days number.
 */
const deactivateDays = (days: number, daysToDeactivate: number): number => {
  return days & ~daysToDeactivate;
};

const dayToString = (day: number, dateLocale: Locale): string => {
  const dayIndex = dayIndexes.findIndex((d) => d === day) + 1;

  return format(previousDay(new Date(), dayIndex), "iiii", {
    locale: dateLocale,
  });
};

/**
 * Transforms a day as use by Kairos in on that
 * can be used be used by date-fns
 * @param {number} The day as in dayIndexes
 * @returns {Day} The value of a day for date-fns
 */
const kairosDayToDateFnsDay = (day: number): Day | undefined => {
  switch (day) {
    case 1:
      return 1;
    case 2:
      return 2;
    case 4:
      return 3;
    case 8:
      return 4;
    case 16:
      return 5;
    case 32:
      return 6;
    case 64:
      return 0;
    default:
      return undefined;
  }
};

const Days = {
  dayIndexes,
  toggleDay,
  dayInDays,
  deactivateDays,
  dayToString,
  kairosDayToDateFnsDay,
};

export default Days;
