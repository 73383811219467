import React, { useEffect, useState } from "react";
import LogoCard from "components/layout/cards/LogoCard";
import CardPage from "components/layout/pages/CardPage";
import { SuspenseLoader } from "components/graphics";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { dangerNotification } from "components/notifications";
import { store } from "react-notifications-component";
import { useTranslation } from "react-i18next";
import { AuthError, RegistrationPage, useAuth } from "services/authentication";
import queryString from "query-string";
import ReferredRegistration from "./ReferredRegistration/ReferredRegistration";
import { UserCredential } from "firebase/auth";
import { BevelButton } from "components/inputs";
import {
  AnalyticsAccountCreated,
  WebAppRegistration,
  WebAppRegistrationCompleted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import Tools from "@neurosolutionsgroup/tools";

const Component = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["webappAuthentication", "webappBase"]);

  const location = useLocation();
  const urlParams = location.search;

  const {
    actions: { isAuthenticated },
  } = useAuth();

  const { handleEvent, handleEvents } = useAnalytics();

  const [referrer, setReferrer] = useState<string | undefined>(undefined);
  const [referrerName, setReferrerName] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const params = queryString.parse(urlParams);

    if (params.referrer) {
      setReferrer(params.referrer.toString());
    }

    if (params.referrerName) {
      setReferrerName(params.referrerName?.toString());
    }
  }, []);

  const onSuccess = (response: UserCredential) => {
    const registrationEvent: WebAppRegistration = {
      name: "Web App Registration",
      eventProperties: {
        type: "email",
        referred: !!referrer,
        referrerId: referrer,
      },
      setProperties: {
        Referrer: referrer,
      },
    };

    const analyticsEvent: AnalyticsAccountCreated = {
      name: "Analytics Account Created",
      eventProperties: {
        "source": "Web App",
        "Email Captured By": "Web App",
      },
      setProperties: {
        email: response.user.email ?? undefined,
        language: Tools.Language.languageCodeToKnownLanguage(i18n.language),
      },
      setOnceProperties: {
        "Email Captured By": "Web App",
        "Email Captured On": new Date().toISOString(),
      },
    };

    handleEvents([registrationEvent, analyticsEvent]);

    history.push({
      pathname: "/account/completeregistration",
      search: urlParams,
    });
  };

  const onReferralSuccess = (
    response: UserCredential,
    firstName?: string,
    lastName?: string
  ) => {
    const event: WebAppRegistration = {
      name: "Web App Registration",
      eventProperties: {
        type: "email",
        referred: !!referrer,
        referrerId: referrer,
      },
      setProperties: {
        Referrer: referrer,
      },
    };

    handleEvent(event);

    if (firstName && lastName) {
      const fullName = `${firstName} ${lastName}`;

      const event: WebAppRegistrationCompleted = {
        name: "Web App Registration Completed",
        setProperties: {
          name: fullName,
        },
        setOnceProperties: {
          "Account Completed On": new Date().toISOString(),
        },
      };

      handleEvent(event);
    }

    history.push("/callbacks/links");
  };

  const onFailure = (err: AuthError) => {
    store.addNotification(
      dangerNotification(
        t("webappBase:forms.errors.title"),
        t("webappBase:forms.errors.general") +
          ": " +
          t("webappBase:forms.errors." + err.code),
        false
      )
    );
  };

  return referrer ? (
    <ReferredRegistration referrerName={referrerName}>
      {isAuthenticated() ? (
        <Redirect to={{ pathname: "/app", search: urlParams }} />
      ) : null}
      <RegistrationPage
        onSuccess={onReferralSuccess}
        onFailure={onFailure}
        referrerId={referrer}
      />
    </ReferredRegistration>
  ) : (
    <CardPage>
      {isAuthenticated() ? (
        <Redirect to={{ pathname: "/app", search: urlParams }} />
      ) : null}
      <LogoCard className="bg-beige">
        <RegistrationPage onSuccess={onSuccess} onFailure={onFailure} />

        <hr />

        <p>{t("pages.registration.menu.loginDescription")}</p>

        <BevelButton
          className="btn btn-primary-alt w-100"
          onClick={() =>
            history.push({ pathname: "/account/login", search: urlParams })
          }
        >
          {t("pages.registration.menu.login")}
        </BevelButton>
      </LogoCard>
    </CardPage>
  );
};

export default function Registration(): JSX.Element {
  return (
    <SuspenseLoader>
      <Component />
    </SuspenseLoader>
  );
}
