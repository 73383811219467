import { FirestoreMedicalChildDocument } from "@neurosolutionsgroup/models";

export const constructMedicalChild = (
  userId: string,
  tenantId: string,
  medicalChildId: string,
  takeMedication: 0 | 1 | 2,
  diagnosis: number[],
  name: string
): FirestoreMedicalChildDocument => {
  return {
    gamerChildId: medicalChildId,
    userId,
    tenantId,
    takeMedication,
    diagnosis,
    name,
    needs: [],
  };
};
