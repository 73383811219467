import { GamerChild, TaskHistory } from "@neurosolutionsgroup/models";
import Tools from "@neurosolutionsgroup/tools";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from "react";

export type ChildrenById = {
  [key: string]: GamerChild;
};

export interface TasksToValidate {
  [id: string]: ValidationTaskHistory[];
}

export interface ValidationTaskHistory extends TaskHistory {
  validated: boolean | undefined;
}

export interface ChildContextData {
  childIds: string[];
  setChildIds: Dispatch<SetStateAction<string[]>>;
  childrenById: ChildrenById;
  setChildrenById: Dispatch<SetStateAction<ChildrenById>>;
  selectedChildId: string | undefined;
  setSelectedChildId: Dispatch<SetStateAction<string | undefined>>;
  tasksToValidate: TasksToValidate;
  setTasksToValidate: React.Dispatch<SetStateAction<TasksToValidate>>;
}

const [useChildContext, ChildContextProvider] =
  Tools.Context.createGenericContext<ChildContextData>();

const ChildProvider = (props: PropsWithChildren): JSX.Element => {
  const [childIds, setChildIds] = useState<string[]>([]);
  const [childrenById, setChildrenById] = useState<ChildrenById>({});
  const [selectedChildId, setSelectedChildId] = useState<string | undefined>(
    undefined
  );
  const [tasksToValidate, setTasksToValidate] = useState<TasksToValidate>({});

  return (
    <ChildContextProvider
      value={{
        childIds,
        setChildIds,
        childrenById,
        setChildrenById,
        selectedChildId,
        setSelectedChildId,
        tasksToValidate,
        setTasksToValidate,
      }}
    >
      {props.children}
    </ChildContextProvider>
  );
};

export { useChildContext, ChildProvider };
