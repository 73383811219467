export const orderedNeedKeys = [
  "organisation",
  "emotions",
  "socialinteraction",
  "impulsivity",
  "hyperactivity",
  "concentration",
  "sleep",
  "screenmanagement",
  "motorskills",
  "learning",
  "language",
  "adaptability",
  "timeperception",
  "eating",
  "memory",
  "hygiene",
];
