import { BevelButton } from "components/inputs";
import { PlainCard } from "components/layout/cards";
import useUserProfile from "hooks/account/useUserProfile";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { store } from "react-notifications-component";
import { successNotification } from "components/notifications";
import { getAuth } from "firebase/auth";
import { SharedReferLink, useAnalytics } from "@neurosolutionsgroup/analytics";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

const Billing = (): JSX.Element => {
  const { t } = useTranslation(["webappSettings", "webappBilling"]);

  const { handleEvent } = useAnalytics();

  const {
    selectors: { userProfile },
  } = useUserProfile();

  useEffect(() => {
    if (window.location.hash === "#referral") {
      window.location.replace("/app/settings#referral");
    }
  }, []);

  const openPortal = async () => {
    FirebaseAPI.Billing.Stripe.createPortalSession().then((response) => {
      window.open(response.redirectUrl as string);
    });
  };

  const getShareLink = useCallback((): string => {
    const id = getAuth().currentUser?.uid;

    if (id && userProfile) {
      return `https://${window.location.hostname}?referrer=${id}&referrerName=${userProfile.firstName}`;
    }

    return "unknown";
  }, [userProfile]);

  const trackShare = (
    method: "link" | "facebook" | "whatsapp" | "twitter" | "email"
  ) => {
    const event: SharedReferLink = {
      name: "Shared Refer Link",
      eventProperties: {
        Method: method,
      },
    };

    handleEvent(event);
  };

  return (
    <div className="mt-3">
      <h2>{t("billing.title")}</h2>
      <PlainCard className="p-3">
        {userProfile?.isSubscribed ? (
          userProfile.isStripe ? (
            <div>
              <p>{t("billing.text.stripe.text")}</p>
              <BevelButton
                className="btn btn-primary mt-2 float-right"
                onClick={openPortal}
              >
                {t("billing.text.stripe.button")}
              </BevelButton>
            </div>
          ) : (
            <div>
              <p>{t("billing.text.app")}</p>
            </div>
          )
        ) : (
          <div>
            <span>{t("billing.text.nosubscription.text")}</span>
            <Link to="/app/billing">
              {t("billing.text.nosubscription.link")}
            </Link>
          </div>
        )}
      </PlainCard>
      <div id="referral" />
      {getAuth().currentUser?.uid ? (
        <PlainCard className="my-3 referral-card">
          <h2>{t("webappBilling:referral.share.title")}</h2>

          <p>{t("webappBilling:referral.share.subtitle")}</p>

          <a href="https://kairosgame.com/referer-un-ami">
            {t("webappBilling:referral.share.link")}
          </a>

          <div className="row">
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1748/kairos-refere-un-ami.png"
                alt=""
              />
              <h5>
                {t("webappBilling:referral.share.information.link.title")}
              </h5>
              <p>{t("webappBilling:referral.share.information.link.text")}</p>
            </div>
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1747/kairos-recoit-des-recompenses.png"
                alt=""
              />
              <h5>
                {t("webappBilling:referral.share.information.discount.title")}
              </h5>
              <p>
                {t("webappBilling:referral.share.information.discount.text")}
              </p>
            </div>
            <div className="col-md-4">
              <img
                src="https://kairosgame.com/media/1746/kairos-costumes-amusants.png"
                alt=""
              />
              <h5>
                {t("webappBilling:referral.share.information.reward.title")}
              </h5>
              <p>{t("webappBilling:referral.share.information.reward.text")}</p>
            </div>
          </div>

          <div>
            <h4>{t("webappBilling:referral.share.linkTitle")}</h4>
          </div>

          <div className="d-flex justify-content-center my-4">
            <div className="d-flex w-100">
              <input readOnly value={getShareLink()} />
              <div
                onClick={() => {
                  trackShare("link");
                  navigator.clipboard.writeText(getShareLink());
                  store.addNotification(
                    successNotification(
                      "",
                      t("webappBilling:referral.share.copied"),
                      true
                    )
                  );
                }}
                className="referral__copy"
              >
                <i className="icon-copy icon-lg"></i>{" "}
                {t("webappBilling:referral.share.copy")}
              </div>
            </div>
          </div>

          <div>
            <h4>{t("webappBilling:referral.share.shareTitle")}</h4>
          </div>

          <div className="d-flex justify-content-center">
            <FacebookShareButton
              url={getShareLink()}
              onClick={() => trackShare("facebook")}
              quote={t("webappBilling:referral.share.sharetext")}
            >
              <FacebookIcon />
            </FacebookShareButton>
            <WhatsappShareButton
              url={getShareLink()}
              onClick={() => trackShare("whatsapp")}
              title={t("webappBilling:referral.share.sharetext")}
            >
              <WhatsappIcon />
            </WhatsappShareButton>
            <TwitterShareButton
              url={getShareLink()}
              onClick={() => trackShare("twitter")}
              title={t("webappBilling:referral.share.sharetext")}
            >
              <TwitterIcon />
            </TwitterShareButton>
            <EmailShareButton
              url={getShareLink()}
              onClick={() => trackShare("email")}
              body={t("webappBilling:referral.share.sharetext")}
            >
              <EmailIcon />
            </EmailShareButton>
          </div>
        </PlainCard>
      ) : null}
    </div>
  );
};

export default Billing;
