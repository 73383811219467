import { CompleteAccountInfo } from "@neurosolutionsgroup/models";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface UserProfileContextData {
  userProfile: CompleteAccountInfo | undefined;
  setUserProfile: React.Dispatch<
    SetStateAction<CompleteAccountInfo | undefined>
  >;
  dataFetched: boolean;
}

const UserProfileContext = React.createContext<UserProfileContextData>({
  userProfile: undefined,
  setUserProfile: () => {},
  dataFetched: false,
});

export const UserProfileProvider = (props: PropsWithChildren): JSX.Element => {
  const [userProfile, setUserProfile] = useState<CompleteAccountInfo>();
  const [dataFetched, setDataFetched] = useState<boolean>(false);

  useEffect(() => {
    FirebaseAPI.Account.getUserInfo().then((userInfo) => {
      setUserProfile(userInfo);
      setDataFetched(true);
    });
  }, []);

  return (
    <UserProfileContext.Provider
      value={{
        userProfile,
        setUserProfile,
        dataFetched,
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileContext;
